import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { PartnerTypeDDL } from '../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL'
import { StatusDDL } from '../../../../../Components/DDL/StatusDDL/StatusDDL'
import { CountryNameDDL } from '../../../../../Components/DDL/CountryNameDDL/CountryNameDDL'
import { ParticipantsTypeDDL } from '../../../../../Components/DDL/ParticipantsTypeDDL/ParticipantsTypeDDL'
import { PledgeParticipantPostAPI, PledgeParticipantTableDataAPI } from '../../../../../Redux/AdminSlice/PledgeParticipantSlice/PledgeParticipantSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ApprovalPartnerPopUp } from '../PartnerApprovalPopUp/ApprovalPartnerPopUp'
import { Loading } from '../../../../../Helper/Loading'

const PledgeParticipant = () => {
    const [params] = useSearchParams()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const username = params.get('username');

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [ApprovalPopUp, setApprovalPopUp] = useState(false)
    const [ActionId, setActionId] = useState('')

    const [Heading, setHeading] = useState('')

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [statusDDL, setstatusDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        setpartnerTypeDDL({
            ...partnerTypeDDL,
            ID: 0,
            Label: "Select...",
        })
        setstatusDDL({
            ...statusDDL,
            ID: 0,
            Label: "Select...",
        })
    }
    const addButtonClick = (item, heading, id) => {
        setApprovalPopUp(true)
        setEditData(item)
        setActionId(id)
        setHeading(heading)
    }
    const handleAddCloseClick = () => {
        setApprovalPopUp(false)
    }
    const handlePost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            M_CountryID: 0,
            M_PartnerTypeID: partnerTypeDDL.ID,
            M_StatusID: statusDDL.ID,
            M_UserID: UserID,
            TOP: '99999',
            token: token,
        }
        dispatch(PledgeParticipantTableDataAPI({ data }))
    }, [IsPost, IsClear, partnerTypeDDL.ID, statusDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.PledgeParticipantTableData)

    const handleApproved = () => {
        const data = {
            M_PledgeRegistrationID: editData?.m_PledgeRegistrationID,
            ActionID: ActionId,
            M_UserID: UserID,
            token: token,
            handlePost: handlePost,
            handleAddCloseClick: handleAddCloseClick
        }
        dispatch(PledgeParticipantPostAPI({ data }))
    }

    return (
        <>
         {isLoading && <Loading />}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div className="row flex-nowrap gr wrapper">
                <Sidebar active='PledgeParticipant' />
                <div className="col py-3 text-dark" id='content'>
                    <div className="page-content">
                        <h5 className=" fw-bold my-3 "> Pledge Participant
                            {/* <!-- <span className="float-end"><a href="#n_popup_login"
                                className="add_btn  mx-1 green_color py-0">Add</a></span> --> */}
                        </h5>
                        <div className="row py-4  card p-2">
                            <div className="col-12 ">

                                <div className="bg-light">
                                    <div className="row grayish py-3 d-flex align-items-end">

                                        <div className="col-12 col-md-6 col-lg-3">
                                            <CountryNameDDL />
                                        </div>

                                        {/* <div className="col-12 col-md-6 col-lg-3">
                                            <PartnerTypeDDL
                                                partnerTypeDDL={partnerTypeDDL}
                                                setpartnerTypeDDL={setpartnerTypeDDL}
                                                lable={true}
                                                // Label={'Partner Type' ? 'Partner Type' : 'Participants Type'}
                                                Label='Participants Type'
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3">
                                            <StatusDDL
                                                statusDDL={statusDDL}
                                                setstatusDDL={setstatusDDL}
                                            />
                                        </div> */}

                                        {/* <div className="col-12 col-md-6 col-lg-1 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            >Clear</button>
                                        </div> */}

                                        <div className="col-12 col-md-6 col-lg-2 mt-3">
                                            <span>Total Count: <span style={{fontWeight:'bold', color:'#006939'}}>0</span></span>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    {/* <!-- <h3 className="lightgreencolor pb-3">Participants</h3> --> */}
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr className="header lightgreenbg whitecolor">
                                                <th>Sr No.</th>                                             
                                                {/* <th>Participants Type</th> */}
                                                <th>Participants  Name</th>
                                                <th>Organization / Company Name</th>
                                                <th>Position IN Organization</th>
                                                <th>Contact Email</th>
                                                <th>Contact Number</th>
                                                <th>Country Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                    <tr key={i}>
                                                        <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>                                                                                          
                                                        <td>{item.participantName ? item.participantName : '-'}</td>
                                                        <td>{item.companyName ? item.companyName : '-'}</td>
                                                        <td>{ '-'}</td>
                                                        <td>{item.email_ID ? item.email_ID : '-'}</td>
                                                        <td>{item.mobileNo ? item.mobileNo : '-'}</td>
                                                        <td>{item.countryName ? item.countryName : '-'}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <span className='' style={{ cursor: 'pointer' }}
                                                                onClick={() => addButtonClick(item, 'Approval PopUp', '1')}
                                                            >
                                                                <i className="fs-6 fa-solid fa-circle-check fa-lg" title ='Approved' style={{ color: "green"}}></i>
                                                            </span>
                                                            <span className='px-1'></span>
                                                            <span style={{ cursor: 'pointer' }}
                                                                onClick={() => addButtonClick(item, 'Reject PopUp', '2')}
                                                            >
                                                                <i className="fs-6 fa-solid fa-circle-xmark fa-lg" title ='Rejected' style={{ color: "red"}}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )) : <tr>No data</tr>
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    ApprovalPopUp && <ApprovalPartnerPopUp
                        open={ApprovalPopUp}
                        heading={Heading}
                        handleAddCloseClick={handleAddCloseClick}
                        handleApproved={handleApproved}
                    />
                }
            </div>
        </>
    )
}

export default PledgeParticipant