import { EventNameDDL } from "../../../../../Components/DDL/EventNameDDL/EventNameDDL"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { PartnerTypeDDL } from "../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import React, { useEffect, useState } from 'react'
import { EventGalleryTableDataAPI } from "../../../../../Redux/AdminSlice/EventGallarySlice/EventGallarySlice"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context"
import { Loading } from "../../../../../Helper/Loading"
import { PublishReportPopUp } from "../KnowledgeRepositoryReport/Action/PublishReportPopUp"
import { IndividualPublishPhotoVideoAPI } from "../../../../../Redux/AdminSlice/EventSlice/EventSlice"

export const PhotoPopUp = (props) => {
    const { handleOnPhotoClick, handleDeletePopUp, EventID, Title, IsPost } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [EditData, setEditData] = useState({
        item:'',
        flag:''
    })

    const [IsPublish, setIsPublish] = useState(false)

    useEffect(() => {
        const data = {
            M_EventID: EventID,
            M_PartnerRegistrationID: '0',
            Flag: RoleID == 1 && Title == 'Photo' ? 'Admin_Photo' : RoleID == 2 && Title == 'Photo' ? 'Partner_Photo' : RoleID == 1 && Title == 'Video' ? 'Admin_Video' : 'Partner_Video',
            M_UserID: UserID,
            token: token,
            Top: '99999',
        }
        dispatch(EventGalleryTableDataAPI({ data }))
    }, [IsPost, IsPublish])

    const { tableData, isLoading } = useSelector(state => state.EventGalleryTableData)

    const PublishButtonClick = (item, flag) => {
        setPublishPopUp(true)
        setEditData({
            item:item,
            flag:flag
        })
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
    }

    const handleIsPost=()=>{
        setIsPublish(!IsPublish)
    }

    const handlePublish = () => {
        const data = {
            M_Event_Wise_PhotoVideoID: EditData.item,
            ActionFlag: EditData.flag,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            // setloading: setloading
        }
        dispatch(IndividualPublishPhotoVideoAPI({ data }))
    }

    return (
        <>
            <div className="modal show fade px-lg-5" id="exampleModal"
                style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
                tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">

                <div class="modal-dialog modal-dialog-centered">
                    {isLoading && <Loading />}
                    <div class="modal-content">
                        <div className="modal-header px-lg-5 bg-white">
                            <h5 className="modal-title lightgreencolor" id="exampleModalLabel">{Title}
                            </h5>
                            <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                                data-bs-dismiss="modal" onClick={handleOnPhotoClick} aria-label="Close"> &times; </button>
                        </div>
                        <div className="modal-body px-5" style={{ overflowY: 'scroll' }}>
                            <div className="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>



                                <div className="row mx-auto ps-4">

                                    {
                                        !isLoading &&
                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                return (

                                                    <>
                                                        {Title == 'Photo' ?

                                                            <div className="col-12 col-md-6 col-lg-3   mt-2 cursor-pointer " width='20%'>
                                                                <div className="card border text-center event_photo_bg" >

                                                                    <span className="text-end pe-2">
                                                                        {
                                                                            (RoleID !== '2' && item.statusID !== 4) &&
                                                                            <i className="fa-solid fa-arrow-up-from-bracket mx-3" title="Publish" onClick={() => PublishButtonClick(item.m_Event_Wise_PhotoID, 'Photo')} style={{ fontSize: '14px', color: 'blue' }}></i>
                                                                        }
                                                                        <i className="fa-solid fa-trash-can " title="Delete" onClick={() => handleDeletePopUp(item.m_Event_Wise_PhotoID, 'Photo')} style={{ fontSize: '14px', color: 'red' }}></i>

                                                                    </span>
                                                                    <div onClick={() => window.open(item.path)}>
                                                                        <span >
                                                                            <img width='80%' height='100px' style={{ objectFit: 'contain' }} src={item.path ? item.path : ''} className="mx-auto mt-2" alt="img not found" />

                                                                        </span>
                                                                        <div className="card-body">
                                                                            <hr className="hr_edit" />
                                                                            <p className="card-text text-dark event_img_textsize">{item.m_PartnerRegistrationName ? item.m_PartnerRegistrationName : '-'}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :

                                                            <div className="col-12 col-md-6 col-lg-3  mt-2 cursor-pointer " width='20%'>
                                                                <div className="card border text-center event_photo_bg" >
                                                                    <span className="text-end pe-2">
                                                                        {
                                                                             (RoleID !== '2' && item.statusID !== 4) &&
                                                                            <i className="fa-solid fa-arrow-up-from-bracket mx-3" title="Publish" onClick={() => PublishButtonClick(item.m_Event_Wise_VideoID, 'Video')} style={{ fontSize: '14px', color: 'blue' }}></i>
                                                                        }

                                                                        <i className="fa-solid fa-trash-can " title="Delete" onClick={() => handleDeletePopUp(item.m_Event_Wise_VideoID, 'Video')} style={{ fontSize: '14px', color: 'red' }}></i>
                                                                        {/* <i class="fa-solid fa-xmark text-danger" style={{fontSize:'14px'}}></i> */}
                                                                    </span>
                                                                    <div onClick={() => window.open(item.path)}>
                                                                        <span >
                                                                            <video width='80%' height='100px' controls muted loop autoPlay onClick={() => window.open(item.path)}>
                                                                                <source src={item.path ? item.path : ''} type="video/mp4" />
                                                                            </video>
                                                                        </span>

                                                                        <div className="card-body">
                                                                            <hr className="hr_edit" />
                                                                            <p className="card-text text-dark event_img_textsize">{item.m_PartnerRegistrationName ? item.m_PartnerRegistrationName : '-'}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                            : <>{isLoading ? '' : 'No Data'}</>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading='Publish'
                    Lable='Publish'
                    handleAddCloseClick={handlePublishCloseClick}
                    handlePublish={handlePublish}
                    loading=''
                />
            }
        </>
    )
}