import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import FAB from '../../../../../Components/FAB/FAB'
import { AddNewsLetterAdmin } from './AddNewsLetterAdmin'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
import { PartnerNameDDL } from '../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL'
import { PublishReportPopUp } from '../KnowledgeRepositoryReport/Action/PublishReportPopUp'
import { NewsLetterDeleteAPI, NewsLetterTableDataAPI } from '../../../../../Redux/AdminSlice/NewsLetterAdminSlice/NewsLetterAdminSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Loading } from '../../../../../Helper/Loading'
import { LogoLoader } from '../../../../../Helper/LogoLoader'

export const NewsLetterAdmin = () => {

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [Heading, setHeading] = useState('')
    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [UploadDate, setUploadDate] = useState('')
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [loading, setloading] = useState(false)

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const PublishButtonClick = (heading, id) => {
        setPublishPopUp(true)
        setEditData()
        setHeading(heading)
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
    }
    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    const handleClear = () => {
        setpartnerNameDDL({
            ...partnerNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setUploadDate('')
    }

    const handleShowPdf = (PDFUrl) => {
        if (PDFUrl) {
            window.open(PDFUrl)
        }
    }

    const handleDelete = () => {
        const data = {
            M_NewsLetterID: editData.m_NewsLetterID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setloading: setloading
        }
        dispatch(NewsLetterDeleteAPI({ data }))
    }

    useEffect(() => {
        const data = {
            M_NewsLetterID: partnerNameDDL.ID,
            MediaDate: UploadDate,
            M_UserID: UserID,
            From: From,
            TOP: '9999',
            token: token,
            setTotalCount: setTotalCount
        }
        dispatch(NewsLetterTableDataAPI({ data }))
    }, [IsPost, UploadDate, partnerNameDDL.ID, To])

    const { tableData, isLoading } = useSelector(state => state.NewsLetterTableData)


    return (
        <>
            {isLoading && <LogoLoader/>}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='NewsLetter' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> News <span className='lightgreencolor'>|</span> Newsletter</h5>
                            {/* <button className='add-btn' onClick={() => navigate()}>Add</button> */}
                            {/* <a href="#" className="text-dark mx-2 mobile_none add-btn" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Add</a> */}
                        </span>

                        {/* Modal */}

                        <div class="row px-lg-5">
                            <div class="col-12 px-lg-5">
                                <div class="modal fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header px-lg-5 bg-white">
                                                <h5 class="modal-title lightgreencolor" id="exampleModalLabel">News Letter
                                                </h5>
                                                <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                                                    data-bs-dismiss="modal" aria-label="Close"> &times; </button>
                                            </div>
                                            <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                                                <form>
                                                    <div className='row'>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">Month
                                                            </label>
                                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>

                                                        {/* <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">Subject
                                                            </label>
                                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div> */}

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">News Letter
                                                            </label>
                                                            <input type="file" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>

                                                    </div>

                                                    {/* <div className='row mt-4'>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">News Letter
                                                            </label>
                                                            <input type="file" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>
                                                    </div> */}

                                                    <p class="float-end py-5">
                                                        <button type="button" class="btn  lightgreenbg text-white px-4">Upload </button>
                                                    </p>


                                                </form>

                                            </div>
                                            {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row py-4  card p-2">
                            <div class="col-12">

                                <div class="col bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">
                                        {/* <div class="col-12 col-md-6 col-lg-3 ">
                                            <PartnerNameDDL
                                                partnerNameDDL={partnerNameDDL}
                                                setpartnerNameDDL={setpartnerNameDDL}
                                            />
                                        </div> */}
                                        <div class="col-12 col-md-6 col-lg-3 ">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">Upload Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="AssetName"
                                                    value={UploadDate}
                                                    onChange={(e) => setUploadDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {/* <div class="col-12 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">NewsLetter Doc</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="AssetName"
                                                // value={AssetName}
                                                // onChange={(e) => setAssetName(e.target.value)}
                                                />
                                            </div>
                                        </div> */}

                                        <div className="col-12 col-md-6 col-lg-1 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            >Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                <table>
                                    <thead>
                                        <tr class="header lightgreenbg whitecolor">
                                            <th>Sr No.</th>
                                            <th>Date</th>
                                            <th>PDF Document</th>
                                            <th>News URL</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                return (
                                                    <tr width='100%'>
                                                        <td className='text-center' width='6%'>{item.rowNum ? item.rowNum : '-'}</td>
                                                        <td width='15%'>{item.mediaDate ? moment(item.mediaDate).format('DD-MM-YYYY') : '-'}</td>
                                                        <td className='text-center' width='10%'>
                                                            <span className='' style={{ cursor: 'pointer' }}>
                                                                <i class="fa-solid fa-file-pdf " title='view' style={{ fontSize: "17px", cursor: "pointer", color: 'red' }}
                                                                    onClick={() => handleShowPdf(item.newsLetterDoc)}
                                                                ></i>
                                                            </span>
                                                        </td>
                                                        <td style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => window.open(item.newsURL)}>{item.newsURL ? item.newsURL : '-'}</td>
                                                        <td width='10%' style={{ textAlign: 'center' }}>
                                                            {/* <span className='' style={{ cursor: 'pointer' }}>
                                                                <i class="fa-solid fa-arrow-up-from-bracket fs-6" title='Publish'
                                                                    onClick={() => PublishButtonClick('Publish PopUp')}
                                                                ></i>
                                                            </span>
                                                            <span className='px-2'></span> */}
                                                            <span style={{ cursor: 'pointer' }}>
                                                                <i class="fa-solid fa-trash-can fs-6" title='Delete' style={{ color: 'red' }}
                                                                    onClick={() => handleDeletePopUp(item)}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                                : <>No data</>
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={tableData.table[0].totalCount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    setrowNo={setrowNo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            } */}
                        </div>

                    </div>
                </div>
            </div>
            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <AddNewsLetterAdmin handleOnAddClick={handleOnAddClick} handleIsPost={handleIsPost} />
            }

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='publish News'
                    handleAddCloseClick={handlePublishCloseClick}
                // handlePublish={handlePublish}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='News'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={loading}
                />
            }
        </>
    )
}
