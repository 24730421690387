import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../Helper/Context"
import { PartenerReportPostAPI } from "../../Redux/AdminSlice/PartnerReportSlice/PartnerReportSlice"
import { PartnerNameDDL } from "../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useState } from "react"
import { Loading } from "../../Helper/Loading"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"

export const AddKnowledgeRepositoryReport = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [isPostLoading, setisPostLoading] = useState(false)
    const [Subject, setSubject] = useState('')
    const [ReportDate, setReportDate] = useState('')
    const [Document, setDocument] = useState(null)

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };


    const handlePost = () => {
        const data = {
            M_PartnerRegistrationID: partnerNameDDL.ID,
            ReportDate: ReportDate,
            ReportSubject: Subject,
            PDF_Doc: Document,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisPostLoading:setisPostLoading
        }
        dispatch(PartenerReportPostAPI({ data }))
    }
    // const { isPostLoading } = useSelector(state => state.PartenerReportPostData)


    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Partner Report
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                            onClick={handleOnAddClick}> &times; </button>
                    </div>



                    <div class="modal-body px-5" style={{ overflowY: 'scroll' }}>

                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <PartnerNameDDL
                                        partnerNameDDL={partnerNameDDL}
                                        setpartnerNameDDL={setpartnerNameDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class=''>Upload Date <AstricSign/>
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={ReportDate}
                                        onChange={(e) => setReportDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row mt-4'>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Subject <AstricSign/>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={Subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Upload PDF <AstricSign/>
                                    </label>
                                    <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        onChange={handleFileInputChange}
                                    />
                                </div>
                            </div>

                            <p class="float-end pt-5 pb-2">
                                <button type="button" class="btn  lightgreenbg text-white px-4"
                                    disabled={
                                        isPostLoading || ReportDate === '' || Subject === '' || Document == null || partnerNameDDL.ID ==0
                                    }
                                    onClick={handlePost}
                                >
                                    {isPostLoading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {!isPostLoading && <span>Upload</span>} 
                                    </button>
                            </p>


                        </form>

                    </div>
                    {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                </div>
            </div>
        </div>
    )
}