import React, { useState } from 'react'
import { EventTypeDDL } from '../../../../../Components/DDL/EventTypeDDL/EventTypeDDL'
import { EventSubTypeDDL } from '../../../../../Components/DDL/EventSubTypeDDL/EventSubTypeDDL'
import { EventPostAPI } from '../../../../../Redux/AdminSlice/EventSlice/EventSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { AstricSign } from '../../../../../Helper/AstricSign/AstricSign'

export const AddEvent = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [isLoading, setisLoading] = useState(false)

    const [eventTypeDDL, seteventTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [eventSubTypeDDL, seteventSubTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [EventTextField, setEventTextField] = useState({
        EventName: '',
        EventStartDate: '',
        EventEndDate: '',
        Location: '',
        Time: ''
    })

    const onchange = (e) => {
        const { name, value } = e.target
        setEventTextField(pre => ({
            ...pre, [name]: value
        }))
    }

    const handleSubmit = () => {
        const data = {
            M_EventTypeID: eventTypeDDL.ID,
            M_EventSubTypeID: eventSubTypeDDL.ID,
            M_PartnerRegistrationID: '1',
            EventStartDateTime: EventTextField.EventStartDate,
            EventEndDateTime: EventTextField.EventEndDate,
            EventSubject: EventTextField.EventName,
            location: EventTextField.Location,
            EventTime: EventTextField.Time,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisLoading: setisLoading
        }
        dispatch(EventPostAPI({ data }))
    }

    // const { isLoading } = useSelector(state => state.EventPostData)

    return (
        <div class="modal show fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Event
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <EventTypeDDL
                                        eventTypeDDL={eventTypeDDL}
                                        seteventTypeDDL={seteventTypeDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <EventSubTypeDDL
                                        eventSubTypeDDL={eventSubTypeDDL}
                                        seteventSubTypeDDL={seteventSubTypeDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Event Name<AstricSign />
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='EventName'
                                        value={EventTextField.EventName}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Event Start Date<AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id=""
                                        aria-describedby="emailHelp"
                                        name='EventStartDate'
                                        value={EventTextField.EventStartDate}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Event End Date<AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='EventEndDate'
                                        value={EventTextField.EventEndDate}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Location<AstricSign />
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='Location'
                                        value={EventTextField.Location}
                                        onChange={onchange}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Time<AstricSign />
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='Time'
                                        value={EventTextField.Time}
                                        onChange={onchange}
                                    />
                                </div>

                            </div>



                            <p class="float-end pt-5 pb-2">
                                <button type="button" class="btn  lightgreenbg text-white px-4"
                                    disabled={isLoading || eventSubTypeDDL.ID == 0 || eventTypeDDL.ID == 0 || EventTextField.EventStartDate == '' ||
                                        EventTextField.EventEndDate == '' || EventTextField.Location == '' || EventTextField.Time == ''}
                                    onClick={handleSubmit}
                                >{isLoading && (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                    {!isLoading && <span>Submit</span>} </button>
                            </p>


                        </form>

                    </div>
                    {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                </div>
            </div>
        </div>
    )
}