import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { PartnerNameDDL } from '../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL'
import { AddKnowledgeRepositoryCaseStudy, AddKnowledgeRepositoryReport } from './AddKnowledgeRepositoryCaseStudy'
import FAB from '../../../../../Components/FAB/FAB'
import { useSearchParams } from 'react-router-dom'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'

import { PartnerCaseStudyDeleteAPI, PartnerCaseStudyPatchAPI, PartnerCaseStudyTableDataAPI } from '../../../../../Redux/AdminSlice/PartnerCaseStudySlice/PartnerCaseStudySlice'
import { Loading } from '../../../../../Helper/Loading'
import { PublishReportPopUp } from '../KnowledgeRepositoryReport/Action/PublishReportPopUp'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
import { Pegination } from '../../../../../Components/Pagination/Pagination'
import { UpdatedPartnerNameDataDDL } from '../../../../../Components/DDL/PartnerNameDDL/UpdatedPartnerNameDDL'
import { LogoLoader } from '../../../../../Helper/LogoLoader'

export const KnowledgeRepositoryCaseStudy = () => {
    const [params] = useSearchParams()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const username = params.get('username');

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [ApprovalPopUp, setApprovalPopUp] = useState(false)
    const [ActionId, setActionId] = useState('')
    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [UploadDate, setUploadDate] = useState('')
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [IsDeleteLoading, setIsDeleteLoading] = useState(false)
    const [Heading, setHeading] = useState('')

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    // const [partnerNameDDL, setpartnerNameDDL] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    const [UpdatedPartnerNameDDL, setUpdatedPartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleClear = () => {
        setUploadDate('')
        setUpdatedPartnerNameDDL({
            ...UpdatedPartnerNameDDL,
            ID: 0,
            Label: "Select...",
        })
    }
    const PublishButtonClick = (item, heading, id) => {
        setPublishPopUp(true)
        setEditData(item)
        setHeading(heading)
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
    }
    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    useEffect(() => {
        const data = {
            M_KnowledgeRepository_CaseStudiesID: 0,
            M_PartnerRegistrationID: UpdatedPartnerNameDDL.ID,
            CaseDate: UploadDate,
            M_UserID: UserID,
            Flag: username == 'admin' ? 'Admin' : 'Partner',
            From: From,
            To: To,
            token: token,
            setTotalCount: setTotalCount
        }
        dispatch(PartnerCaseStudyTableDataAPI({ data }))
    }, [IsPost, IsClear, UploadDate, UpdatedPartnerNameDDL.ID, To])

    const { tableData, isLoading } = useSelector(state => state.PartnerCaseStudyTableData)

    const handlePublish = () => {
        const data = {
            M_KnowledgeRepository_CaseStudiesID: editData.m_KnowledgeRepository_CaseStudiesID,
            ActionID: '',
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
        }
        dispatch(PartnerCaseStudyPatchAPI({ data }))
    }

    const handleDelete = () => {
        const data = {
            M_KnowledgeRepository_CaseStudiesID: editData.m_KnowledgeRepository_CaseStudiesID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setIsDeleteLoading: setIsDeleteLoading
        }
        dispatch(PartnerCaseStudyDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.PartnerCaseStudyDeleteData)

    return (
        <>
            {isLoading && <LogoLoader/>}
            {isDeleteLoading && <LogoLoader/>}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='PartnerCaseStudy' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 ">
                                Admin Case Study
                            </h5>
                        </span>

                        <div class="row py-4  card p-2">
                            <div class="col-12 ">

                                <div class="bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">
                                        {
                                            RoleID == '1' &&
                                            <div class="col-12 col-md-6 col-lg-3">
                                                {/* <PartnerNameDDL
                                                partnerNameDDL={partnerNameDDL}
                                                setpartnerNameDDL={setpartnerNameDDL}
                                            /> */}
                                                <UpdatedPartnerNameDataDDL
                                                    UpdatedPartnerNameDDL={UpdatedPartnerNameDDL}
                                                    setUpdatedPartnerNameDDL={setUpdatedPartnerNameDDL}
                                                    Flag="PartnerCaseStudy_Admin"
                                                />
                                            </div>
                                        }
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">Upload Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="UploadDate"
                                                    value={UploadDate}
                                                    onChange={(e) => setUploadDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            >Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    <table>
                                        <thead>
                                            <tr class="header lightgreenbg whitecolor">
                                                <th>Sr No.</th>
                                                <th>Upload Date</th>
                                                <th>Partner Name</th>
                                                <th>Subject</th>
                                                <th>View</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                                                    <tr key={i}>
                                                        <td  className='w-5 text-center' width='6%'>{item.rowNum}</td>
                                                        <td>{item.caseDate ? moment(item.caseDate).format("DD-MM-YYYY") : '-'}</td>
                                                        <td>{item.m_PartnerRegistrationName ? item.m_PartnerRegistrationName : '-'}</td>
                                                        <td>{item.caseStudiesSubject ? item.caseStudiesSubject : '-'}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                                    <i class="fa-solid fa-file-pdf " title='view' style={{ fontSize: "17px", cursor: "pointer", color: 'red' }}
                                                                        onClick={() => {
                                                                            window.open(item.caseStudiesDoc ? item.caseStudiesDoc : 'ImageNotFound')
                                                                        }}
                                                                    ></i>
                                                                </a>
                                                            }

                                                        </td>
                                                        <td  style={{ textAlign: 'center' }}>
                                                            {
                                                                (RoleID !== '2' && item.statusID != 4) ?
                                                                    <>
                                                                        <span className='' style={{ cursor: 'pointer' }}>
                                                                            <i class="fa-solid fa-arrow-up-from-bracket fs-6" title='Publish'
                                                                                onClick={() => PublishButtonClick(item, 'Publish PopUp')}
                                                                            ></i>
                                                                        </span>
                                                                        {/* <span className='px-2'></span> */}
                                                                    </>
                                                                    : <></>
                                                            }
                                                            <span className='ps-2' style={{ cursor: 'pointer' }}>
                                                                <i class="fa-solid fa-trash-can fs-6" title='Delete' style={{ color: 'red' }}
                                                                    onClick={() => handleDeletePopUp(item)}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )) : <tr>No data</tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {tableData && tableData.table && tableData.table.length > 0 &&
                                    <Pegination
                                        PerPageCount={PerPageCount}
                                        TotalCount={tableData.table[0].totalCount}
                                        setFrom={setFrom}
                                        setTo={setTo}
                                        setrowNo={setrowNo}
                                        CurrentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <AddKnowledgeRepositoryCaseStudy handleOnAddClick={handleOnAddClick} handleIsPost={handleIsPost} />
            }
            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='publish Case study'
                    handleAddCloseClick={handlePublishCloseClick}
                    handlePublish={handlePublish}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='Case study'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={IsDeleteLoading}
                />
            }
        </>
    )
}
