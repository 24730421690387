
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";



// Get Table Data
export const PartenerApprovalTableDataAPI = createAsyncThunk(" PartenerApprovalTableData", async ({ data }) => {
    const {
        M_CountryID,
        M_StatusID,
        M_UserID,
        token,
        From,
        To,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_Admin_M_PartnerRegistration_Select?M_CountryID=${M_CountryID ? M_CountryID : '0'}&M_StatusID=${M_StatusID ? M_StatusID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const PartenerApprovalTableDataSlice = createSlice({
    name: " PartenerApprovalTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerApprovalTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartenerApprovalTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PartenerApprovalTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PartenerApprovalTableDataReducer = PartenerApprovalTableDataSlice.reducer

// InsertUpdate Table Data

export const PartenerApprovalPostAPI = createAsyncThunk("PartenerApprovalPost", async ({ data }) => {
    const {
        m_PartnerRegistrationID,
        ActionID,
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick,
        handleSendEmail,
        setisARLoading,
        handleClear,
        apiFlag
    } = data
    setisARLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("m_PartnerRegistrationID", m_PartnerRegistrationID);
    formdata.append("ActionID", ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_Admin_M_PartnerRegistration_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                handleSendEmail()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setisARLoading(false)
            return result

        })
})

const PartenerApprovalPostSlice = createSlice({
    name: "PartenerApprovalPost",
    initialState: {
        isARLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerApprovalPostAPI.pending, (state, action) => {
            state.isARLoading = true;
        });
        builder.addCase(PartenerApprovalPostAPI.fulfilled, (state, action) => {
            state.isARLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PartenerApprovalPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isARLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartenerApprovalPostReducer = PartenerApprovalPostSlice.reducer



