import { useState } from "react"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch } from "react-redux"
import NewsLetter from "../../Multimedia/NewsLetter/NewsLetter"
import { NewsLetterPostAPI } from "../../../../../Redux/AdminSlice/NewsLetterAdminSlice/NewsLetterAdminSlice"
import { useSearchParams } from "react-router-dom"
import { AstricSign } from "../../../../../Helper/AstricSign/AstricSign"
import { RegExIsURLValid } from "../../../../../Helper/Regx/Regx"

export const AddNewsLetterAdmin = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [params] = useSearchParams()
    const username = params.get('username');

    const [Subject, setSubject] = useState('')
    const [Document, setDocument] = useState('')
    const [NewsLetterDate, setNewsLetterDate] = useState('')
    const [NewsLetterURL, setNewsLetterURL] = useState('')
    const [loading, setloading] = useState(false)

    const [NewType, setNewType] = useState('')

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };


    const handlePost = () => {
        const data = {
            Subject: Subject,
            MediaDate: NewsLetterDate,
            NewsLetterDoc: Document,
            NewsURL: NewsLetterURL,
            NewType: NewType,
            M_UserID: UserID,
            token: token,
            Flag: username == 'admin' ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setloading: setloading
        }
        dispatch(NewsLetterPostAPI({ data }))
    }

    const [IsURLValid, setIsURLValid] = useState(false)
    const handleCheckEmail = (e) => {
        setNewsLetterURL(e.target.value)
        const IsValid = RegExIsURLValid(e.target.value)
        setIsURLValid(IsValid)
        return IsValid
    }

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor" id="exampleModalLabel">ADD News / NewsLetter
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div className="modal-body px-5" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                <div className="col-12 ps-4">
                                    <label for="exampleInputEmail1" className="form-labels me-3">News Type <AstricSign/>: </label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio1"
                                            value='News'
                                            onChange={(e) => setNewType(e.target.value)}
                                        />
                                        <label className="form-check-label fw-bold" for="inlineRadio1">News</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio2"
                                            value="NewsLetter"
                                            onChange={(e) => setNewType(e.target.value)}
                                        />
                                        <label className="form-check-label fw-bold" for="inlineRadio2">NewsLetter</label>
                                    </div>
                                </div>
                                <hr className="mt-3" />
                                <div className="row mt-2">
                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <label for="exampleInputEmail1" className="form-labels">Subject<AstricSign /></label>
                                        <input type="text" className="form-control"
                                            aria-describedby="emailHelp"
                                            value={Subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <label for="exampleInputEmail1" className="form-labels">Date<AstricSign /></label>
                                        <input type="date" className="form-control"
                                            aria-describedby="emailHelp"
                                            value={NewsLetterDate}
                                            onChange={(e) => setNewsLetterDate(e.target.value)}
                                        />

                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 mt-3">
                                        <label for="exampleInputEmail1" className="form-labels">PDF
                                        </label>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            onChange={handleFileInputChange}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 mt-3">
                                        <label for="exampleInputEmail1" className="form-labels"> URL
                                        </label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            value={NewsLetterURL}
                                            // onChange={(e) => setNewsLetterURL(e.target.value)}
                                            onChange={(e) => handleCheckEmail(e)}
                                        />
                                        {
                                            IsURLValid && <text className="RegxValidation">Please enter valid URL</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className='row mt-4'>

                                <div className="col-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Subject
                                    </label>
                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Upload PDF
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>
                            </div> */}

                            <p className="float-end pt-5 pb-2">
                                <button type="button" className="btn  lightgreenbg text-white px-4"
                                    disabled={IsURLValid || loading || NewType =='' || Subject == '' || NewsLetterDate == '' 
                                    // || Document == '' || NewsLetterURL == ''
                                }
                                    onClick={handlePost}
                                >
                                    {loading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {!loading && <span>Upload</span>}
                                </button>
                            </p>


                        </form>

                    </div>
                    {/* <!-- <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> --> */}
                </div>
            </div>
        </div>
    )
}