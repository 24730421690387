
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseURL";

// InsertUpdate Table Data

export const PostPartnerRegistrationAPI = createAsyncThunk("PostPartnerRegistration", async ({ data }) => {
    const {
        M_CountryNameID,
        G_Planet_text,
        M_PartnerTypeID,
        M_SubPartnerTypeID,
        Full_Name,
        CompanyName,
        Position_InOrganization,
        ContactPersonName,
        Email_ID,
        MobileNo,
        Address,
        PostalCode,
        Username,
        Password,
        AreaOfInterest,
        Contribution,
        OtherText,
        M_UserID,
        handlePostRegistration,
        handleSendEmail,
        setPostMsg,
        handleClear
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();

    formdata.append("M_CountryNameID", M_CountryNameID);
    formdata.append("G_Planet_text", Full_Name);
    formdata.append("Full_Name", '');
    formdata.append("CompanyName", CompanyName);
    formdata.append("Position_InOrganization", Position_InOrganization);
    formdata.append("Email_ID", Email_ID);
    formdata.append("MobileNo", MobileNo);
    formdata.append("Username", Username);
    formdata.append("Password", Password);
    formdata.append("AreaOfInterest", AreaOfInterest);
    formdata.append("Contribution", Contribution);
    formdata.append("OtherText", OtherText !== undefined ? OtherText : '');
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_PartnerRegistration_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                handleSendEmail(result && result.data)
                handleClear()
                return result.data
            } else {
                handlePostRegistration()
                handleClear()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostPartnerRegistrationSlice = createSlice({
    name: "PostPartnerRegistration",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostPartnerRegistrationAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostPartnerRegistrationAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostPartnerRegistrationAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostPartnerRegistrationReducer = PostPartnerRegistrationSlice.reducer


export const PostQueryAPI = createAsyncThunk("PostQuery", async ({ data }) => {
    const {
        M_CountryNameID,
        Full_Name,
        Email_ID,
        MobileNo,
        Address,
        Query,
        M_UserID,
        handlePostRegistration,
        setPostMsg,
        handleClear
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();

    formdata.append("M_CountryNameID", M_CountryNameID);
    formdata.append("Full_Name", Full_Name);
    formdata.append("MobileNo", MobileNo);
    formdata.append("Email_ID", Email_ID);
    formdata.append("Location", Address);
    formdata.append("Query", Query);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Query_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                handleClear()
                return result.data
            } else {
                handlePostRegistration()
                handleClear()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostQuerySlice = createSlice({
    name: "PostQuery",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostQueryAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostQueryAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostQueryAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostQueryReducer = PostQuerySlice.reducer


