import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { StatusDDL } from '../../../../../Components/DDL/StatusDDL/StatusDDL'
import { CountryNameDDL } from '../../../../../Components/DDL/CountryNameDDL/CountryNameDDL'
import { CountryDDL } from '../../../../../Components/DDL/CountryDDL/CountryDDL'
import { useDispatch, useSelector } from 'react-redux'
import { ReceivedQueryGetTableDataAPI, ReplyPostAPI } from '../../../../../Redux/AdminSlice/ReceivedQuerySlice/ReceivedQuerySlice'
import { useAuthState } from '../../../../../Helper/Context'
import { Loading } from '../../../../../Helper/Loading'
import { Pegination } from '../../../../../Components/Pagination/Pagination'
import { ReplyPopUp } from './ReplyPopUp'
import { UpdatedCountryDDL } from '../../../../../Components/DDL/CountryDDL/UpdatedCountryDDL'
import { LogoLoader } from '../../../../../Helper/LogoLoader'
import { ApprovalPartnerPopUp } from '../PartnerApprovalPopUp/ApprovalPartnerPopUp'

const QueryScreen = () => {
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    const dispatch = useDispatch()

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [ShowReplayPopUp, setShowReplayPopUp] = useState(false)
    const [ApprovalPopUp, setApprovalPopUp] = useState(false)
    const [EditData, setEditData] = useState()
    const [Flag, setFlag] = useState('')
    const [IsPost, setIsPost] = useState(false)
    const [ReplyText, setReplyText] = useState('')
    const [loading, setloading] = useState(false)

    const [statusDDL, setstatusDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    // const [CountryTypeDDL, setCountryTypeDDL] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    const [CountryDDL, setCountryDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleShowReplayPopUp = (item, id) => {
        setShowReplayPopUp(!ShowReplayPopUp)
        setEditData(item)
        setFlag(id)
    }

    const handleClear = () => {
        setCountryDDL({
            ...CountryDDL,
            ID: 0,
            Label: 'Select...'
        })
        setstatusDDL({
            ...statusDDL,
            ID: 0,
            Label: 'Select...'
        })
    }

    useEffect(() => {
        const data = {
            M_QueryID: statusDDL.ID,
            M_CountryNameID: CountryDDL.ID,
            Query: '',
            M_UsersID: UserID,
            To: To,
            token: token,
            From: From,
            setTotalCount: setTotalCount
        }
        dispatch(ReceivedQueryGetTableDataAPI({ data }))
    }, [CountryDDL.ID, statusDDL.ID, To, IsPost])

    const { tableData, isLoading } = useSelector(state => state.ReceivedQueryGetTableData)

    const addButtonClick = (item, id) => {
        setApprovalPopUp(true)
        setEditData(item)
        setFlag(id)
        setReplyText('')
        // setHeading(heading)
    }
    const handleAddCloseClick = () => {
        setApprovalPopUp(false)
    }
    const handlePost = () => {
        setShowReplayPopUp(false)
        setIsPost(!IsPost)
        setReplyText('')
    }

    const handleApproved = () => {

        const data = {
            M_QueryID: EditData.m_QueryID,
            QueryReply: ReplyText,
            M_UserID: UserID,
            token: token,
            ActionFlag: Flag,
            setloading:setloading,
            handlePost,
            handleAddCloseClick
        }
        dispatch(ReplyPostAPI({ data }))

    }

    return (
        <>
            {isLoading && <LogoLoader/>}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='ReceivedQuery' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <h5 class=" fw-bold my-3 ">Received Query
                            {/* <!-- <span class="float-end"><a href="#n_popup_login"
                                class="add_btn  mx-1 green_color py-0">Add</a></span> --> */}
                        </h5>
                        <div class="row py-4  card p-2">
                            <div class="col-12 ">

                                <div class="bg-light ">
                                    <div class="row grayish py-3 d-flex align-items-end">

                                        <div class="col-12 col-md-6 col-lg-3">
                                            {/* <label className="d-block" htmlFor="NameofDepartment">Country Name</label> */}
                                            {/* <CountryDDL
                                                CountryTypeDDL={CountryTypeDDL}
                                                setCountryTypeDDL={setCountryTypeDDL}
                                            /> */}

                                            <UpdatedCountryDDL
                                                CountryDDL={CountryDDL}
                                                setCountryDDL={setCountryDDL}
                                                Flag="ReceiveQuary"
                                            />
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <StatusDDL
                                                statusDDL={statusDDL}
                                                setstatusDDL={setstatusDDL}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            >Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>

                                    </div>
                                    <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                        {/* <!-- <h3 class="lightgreencolor pb-3">Participants</h3> --> */}
                                        <table>
                                            <thead>
                                                <tr class="header lightgreenbg whitecolor">
                                                    <th>Sr No.</th>
                                                    <th>Country Name</th>
                                                    <th>Person Name</th>
                                                    <th>Email ID</th>
                                                    <th>Mobile Number</th>
                                                    <th>Query</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <td className='text-center' width='6%'>{item.rowNum ? item.rowNum : '-'}</td>
                                                                <td>{item.countryName ? item.countryName : '-'}</td>
                                                                <td>{item.full_Name ? item.full_Name : '-'}</td>
                                                                <td>{item.email_ID ? item.email_ID : '-'}</td>
                                                                <td>{item.mobileNo ? item.mobileNo : '-'}</td>
                                                                <td>{item.query ? item.query : '-'}</td>
                                                                <td style={{ textAlign: 'center' }}>

                                                                    {

                                                                        item.statusID == 1 ?

                                                                            <span style={{ cursor: 'pointer' }}>
                                                                                <i className="fa-solid fa-reply fs-6" title='Replay'
                                                                                    onClick={() => handleShowReplayPopUp(item, 'Reply')}
                                                                                ></i>
                                                                            </span>
                                                                            :
                                                                            <span className='' style={{ cursor: 'pointer' }}>
                                                                                <i class="fa-solid fa-circle-check fs-6" title='Acknowledge' style={{ color: 'green' }}
                                                                                    onClick={() => addButtonClick(item, 'Acknowledge')}></i>
                                                                            </span>


                                                                    }


                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                        : <></>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {
                ShowReplayPopUp &&
                <ReplyPopUp
                    handleShowReplayPopUp={handleShowReplayPopUp}
                    setReplyText={setReplyText}
                    ReplyText={ReplyText}
                    handleIsPost={handleApproved}
                    loading={loading}
                />
            }

            {
                ApprovalPopUp && <ApprovalPartnerPopUp
                    open={ApprovalPopUp}
                    heading='Acknowledge'
                    handleAddCloseClick={handleAddCloseClick}
                    handleApproved={handleApproved}
                    isARLoading=''
                    loading={loading}
                />
            }
        </>
    )
}

export default QueryScreen