
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';

export const HomeEngage = () => {
    const navigate = useNavigate()
    return (
        <div className="row  px-0 px-lg-5 pb-3 " id="Engage">
            <div className="col-12 col-md-6 text-center text-lg-start   py-5">
                <h2 className="lightgreencolor pb-3">Engage
                </h2>
                <p>
                    The <span className="lightgreencolor"> "Engage"</span> tab is your gateway to actively participate in
                    environmental discussions, stay updated on events, and gain insights through informative webinars.
                    Connect with a global community of like-minded individuals and organizations dedicated to
                    environmental transformation. Explore these options below to maximize your engagement.
                </p>
                <div className="py-3">
                    <button onClick={() => navigate('/Commingsoon')} className="all_button">Explore <i className="fas fa-angle-right"></i></button>
                </div>
            </div>

            <div className="col-12 col-md-6">
                <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/ENGAGE1-01.png?updatedAt=1701510153055" height='100%' width="100%" alt="" />
                {/* <img src="assets/img/ENGAGE1-01.png" alt="" style={{ width: '100%' }} /> */}
            </div>
        </div>
    )
}