var url = window.location.origin
export const BaseUrl =
    url === 'http://ggci-world.in' ? 'http://api.ggci-world.in' :
        url === 'https://ggci-world.in' ? 'https://api.ggci-world.in' :
            url === 'http://www.ggci-world.in' ? 'http://www.api.ggci-world.in' :
                url === 'https://www.ggci-world.in' ? 'https://www.api.ggci-world.in' :

                url === 'https://gplanet-world.org' ? 'https://api.gplanet-world.org' :
                url === 'http://gplanet-world.org' ? 'http://api.gplanet-world.org' :

                    'https://api.gplanet-world.org'
                    // "http://demoapi.ggci-world.in"






