import { useEffect, useState } from "react"
import { IsPasswordMatch, RegExEmail, RegExNumbersOnly, RegExOnlyText, RegExPassword } from "../../Helper/Regx/Regx"
import { PartnerTypeDDL } from "../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import { useDispatch, useSelector } from "react-redux"
import { PostPartnerRegistrationAPI } from "../../Redux/PartnerRegistrationSlice/PartnerRegistrationSlice"
import { useAuthState } from "../../Helper/Context"
import { OrganiztionTypeDDL } from "../../Components/DDL/OrganiztionTypeDDL/OrganiztionTypeDDL"
import { CountryDDL } from "../../Components/DDL/CountryDDL/CountryDDL"
import { CountryCodeDDLAPI } from "../../Redux/DDLSlice"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"
import { PostSendEmailAPI } from "../../Redux/SendEmailSlice/SendEmailSlice"

export const RegistrationPopUp = (props) => {
    const { handleShowRegistration } = props
    const dispatch = useDispatch()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [FormData, setFormData] = useState({
        FullName: '',
        OrganizationName: '',
        Address: '',
        PostalCode: '',
        ConatctPerson: '',
        Position: '',
        Email: '',
        ContactNumber: '',
        UserName: '',
        Password: '',
        ConfirmPassword: '',
        OtherReason: '',
        AreaOfInterest: [],
        Contribute: []
    })

    const [showOther, setshowOther] = useState(false)
    const [CountryCodeID, setCountryCodeID] = useState('')
    const [IsAreaofInterest, setIsAreaofInterest] = useState()
    const [IsContribute, setIsContribute] = useState('')
    const [IsDisable, setIsDisable] = useState(true)
    const [PostMsg, setPostMsg] = useState('')
    const [loading, setloading] = useState(false)

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData(pre => ({
            ...pre, [name]: value
        }))
    }

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [organizationTypeDDL, setorganizationTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [CountryTypeDDL, setCountryTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [CountryCode, setCountryCode] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleAreaOfInterest = (e) => {
        if (e.target.checked) {
            FormData.AreaOfInterest.push(e.target.value)
        } else {
            handleAreaOfInterestFilter(e.target.value)
        }
        setIsAreaofInterest(FormData.AreaOfInterest.length)
    }

    const handleAreaOfInterestFilter = (item) => {
        FormData.AreaOfInterest = FormData.AreaOfInterest.filter((i) => {
            return i !== item
        })
    }

    const handleContribute = (e) => {
        if (e.target.checked) {
            FormData.Contribute.push(e.target.value)
        } else {
            handleContributeFilter(e.target.value)
        }
        setIsContribute(FormData.Contribute.length)
    }

    const handleContributeFilter = (item) => {
        FormData.Contribute = FormData.Contribute.filter((i) => {
            return i !== item
        })
    }

    const onChangeOther = (e) => {
        if (e.target.checked) {
            setshowOther(true)
        } else {
            setshowOther(false)
        }
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        onChange(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const [IsValidEmail, setIsValidEmail] = useState(false)
    const handleCheckEmail = (e) => {
        onChange(e)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumber = (e) => {
        onChange(e)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }

    const [IsValidPassword, setIsValidPassword] = useState(false)
    const handleCheckPassword = (e) => {
        setFormData({
            ...FormData,
            ConfirmPassword: '',
        })
        onChange(e)
        const IsValid = RegExPassword(e.target.value)
        setIsValidPassword(IsValid)
        return IsValid
    }

    const [IsConfirmValidPassword, setIsConfirmValidPassword] = useState(false)
    const handleCheckConfirmPassword = (e) => {
        onChange(e)
        if (FormData.Password == e.target.value) {
            setIsConfirmValidPassword(false)
        } else {
            setIsConfirmValidPassword(true)
        }
    }


    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye-slash") {
            y.className = "fa fa-eye";
        } else {
            y.className = "fa fa-eye-slash";
        }
    }

    const handleSendEmail = (result) => {
        
        const data={
            Full_Name : FormData.FullName,
            Email_ID : FormData.Email,
            Username : FormData.UserName,
            Password : FormData.ConfirmPassword,
            Flag :'PartnerRegistration'
        }
        if (result && result[0].responsecode < 3) {
            dispatch(PostSendEmailAPI({ data }))
        }
    }
    
    const handlePostRegistration = (data) => {
        setPostMsg(data)
        setTimeout(() => {
            setloading(false)
            // handleShowRegistration()
        }, 2000);
    }

    const handleClear = () => {
        setCountryTypeDDL({
            ...CountryTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
        setFormData({
            FullName: '',
            OrganizationName: '',
            Address: '',
            PostalCode: '',
            ConatctPerson: '',
            Position: '',
            Email: '',
            ContactNumber: '',
            UserName: '',
            Password: '',
            ConfirmPassword: '',
            OtherReason: '',
            AreaOfInterest: [],
            Contribute: []
        })
        document.getElementById('science').checked = false
        document.getElementById('policy').checked = false
        document.getElementById('practice').checked = false
        document.getElementById('participate').checked = false
        document.getElementById('commit').checked = false
        document.getElementById('engage').checked = false
        document.getElementById('other').checked = false
        setshowOther(false)
    }

    const handleSubmit = () => {
        setloading(true)

        const data = {
            M_CountryNameID: CountryCodeID ? CountryCodeID : '0',
            G_Planet_text: FormData.FullName,
            M_PartnerTypeID: partnerTypeDDL.ID,
            M_SubPartnerTypeID: organizationTypeDDL.ID,
            Full_Name: FormData.FullName,
            CompanyName: FormData.OrganizationName,
            Position_InOrganization: FormData.Position,
            ContactPersonName: FormData.ConatctPerson,
            Email_ID: FormData.Email,
            MobileNo: FormData.ContactNumber,
            Address: FormData.Address,
            PostalCode: FormData.PostalCode,
            Username: FormData.UserName,
            Password: FormData.Password,
            AreaOfInterest: FormData.AreaOfInterest,
            Contribution: FormData.Contribute,
            OtherText: FormData.OtherReason,
            M_UserID: '0',
            handlePostRegistration,
            handleSendEmail,
            setPostMsg: setPostMsg,
            handleClear: handleClear
        }
        dispatch(PostPartnerRegistrationAPI({ data }))
    }

    const { isRegistrationLoading } = useSelector(state => state.PostPartnerRegistrationData)

    useEffect(() => {
        const data = { UserID, token, CountryTypeDDL, setCountryCodeID }
        dispatch(CountryCodeDDLAPI({ data }))
    }, [CountryTypeDDL])

    const { DDLData } = useSelector(state => state.CountryCodeDDLData)


    const IsValidate = () => {
        if (!showOther) {
            if (
                IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword ||
                FormData.AreaOfInterest.length == '0' || FormData.Contribute.length == '0' || FormData.Email == '' ||
                CountryTypeDDL.ID == '0' || FormData.FullName == '' || FormData.OrganizationName == '' ||
                FormData.Position == '' || FormData.UserName == '' ||
                FormData.Password == '' || FormData.ConfirmPassword == '') {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
        else if (showOther) {
            if (
                IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword ||
                FormData.AreaOfInterest.length == '0' || FormData.Contribute.length == '0' || FormData.Email == '' ||
                CountryTypeDDL.ID == '0' || FormData.FullName == '' || FormData.OrganizationName == '' ||
                FormData.Position == '' || FormData.UserName == '' ||
                FormData.Password == '' || FormData.ConfirmPassword == '' || FormData.OtherReason == '') {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
    }

    useEffect(() => {
        IsValidate()
    }, [FormData, IsAreaofInterest, IsContribute, showOther])

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content" style={{ height: '90vh' }}>
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor " id="exampleModalLabel">Global Green Credit Initiative
                            Registration
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            onClick={handleShowRegistration}
                        > &times; </button>
                    </div>

                    <div className="modal-body px-5 whats_new"
                        // style={{ height: '600px', overflowY: 'scroll' }}
                    >

                        <form>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1"
                                            className="form-label">Country/Region<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <CountryDDL
                                            CountryTypeDDL={CountryTypeDDL}
                                            setCountryTypeDDL={setCountryTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Nominate a focal point for Global Green Credit Initiative<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="FullName"
                                            value={FormData.FullName}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Partner Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="FullName"
                                            value={FormData.FullName}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label"> Organization Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="OrganizationName"
                                            value={FormData.OrganizationName}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label"> Position/Role in the
                                            Organization<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Position"
                                            value={FormData.Position}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Contact Email<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Email"
                                            value={FormData.Email}
                                            // onChange={onChange} 
                                            onChange={(e) => handleCheckEmail(e)}
                                        />
                                        {
                                            IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">

                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Contact
                                            Number</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <span className="input-group-text">

                                                <select className="" style={{ border: 'none', backgroundColor: '#E9ECEF' }}
                                                    onChange={(e) => {
                                                        setCountryCodeID(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        DDLData && DDLData.length > 0 && DDLData.map((item) => (
                                                            <option key={item.m_CountryID} value={item.m_CountryID} selected>{item.mobileCountryCode}</option>
                                                        ))
                                                    }
                                                </select>
                                            </span>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ContactNumber"
                                                value={FormData.ContactNumber}
                                                // onChange={onChange} 
                                                onChange={(e) => handleCheckNumber(e)}
                                            />
                                            {
                                                IsValidNumber && <text className="RegxValidation">Please enter Numeric characters only</text>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Partner Type<AstricSign /></label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <PartnerTypeDDL
                                            label={true}
                                            partnerTypeDDL={partnerTypeDDL}
                                            setpartnerTypeDDL={setpartnerTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div> */}

                            {
                                partnerTypeDDL.ID > 1 &&
                                <div className="row pt-4">
                                    <div className="col-12 col-md-5">
                                        <div className="col-12">
                                            <label for="exampleInputEmail1" className="form-label">
                                                {partnerTypeDDL.ID == 2 ? 'Organization Type' : 'Academia & Researchers'}<AstricSign />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <div className="col-12">
                                            <OrganiztionTypeDDL
                                                organizationTypeDDL={organizationTypeDDL}
                                                setorganizationTypeDDL={setorganizationTypeDDL}
                                                PartnerTypeID={partnerTypeDDL.ID}
                                                Label=''
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Address<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="Address"
                                            value={FormData.Address}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Postal Code<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="PostalCode"
                                            value={FormData.PostalCode}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Contact
                                            Person<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ConatctPerson"
                                            value={FormData.ConatctPerson}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div> */}







                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="Username" className="form-label">Username<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="UserName"
                                            value={FormData.UserName}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="password" className="form-label">Password<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="Password"
                                            autoComplete="new-password"
                                            value={FormData.Password}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckPassword(e)}
                                        />
                                        {
                                            IsValidPassword &&
                                            <div className="RegxValidation">
                                                <text >Please enter Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.</text>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="password" className="form-label">Confirm Password<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="ConfirmPassword"
                                                id="pass"
                                                value={FormData.ConfirmPassword}
                                                onChange={(e) => handleCheckConfirmPassword(e)}
                                            />
                                            <span className="input-group-text">

                                                <span id='eye' className="fa fa-eye-slash"
                                                    onClick={() => showPass()}

                                                ></span>
                                            </span>
                                        </div>
                                        {
                                            IsConfirmValidPassword &&
                                            <div className="RegxValidation">
                                                <text >Password Mismatch</text>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-5" />
                            <h5 className="pt-3">Interest and Contribution:</h5>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">1. Area of
                                            Interest<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="1"
                                                name="Science"
                                                id="science"
                                                onChange={handleAreaOfInterest}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Science
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="2"
                                                name="Policy"
                                                id="policy"
                                                onChange={handleAreaOfInterest}
                                            />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Policy
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="3"
                                                name="Practice"
                                                id="practice"
                                                onChange={handleAreaOfInterest}
                                            />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Practice
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">2. How would you like
                                            to contribute?<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input "
                                                type="checkbox"
                                                value="1"
                                                name="Participate"
                                                id="participate"
                                                onChange={handleContribute}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Participate in Dialogue Series
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="2"
                                                name="Commit"
                                                id="commit"
                                                onChange={handleContribute}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {/* Commit to LiFE Pledge */}
                                                Upload Reports / Case Studies
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="3"
                                                name="Engage"
                                                id="engage"
                                                onChange={handleContribute}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Engage in Green Credit Portal activities
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="4"
                                                name="Other"
                                                id="other"
                                                onChange={(e) => { handleContribute(e); onChangeOther(e) }}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Other
                                            </label>
                                        </div>
                                        {
                                            showOther == true &&
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                value={FormData.OtherReason}
                                                placeholder="Other Reason"
                                                name="OtherReason"
                                                onChange={onChange}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>

                            <p className="py-5">
                                <div className="col-12 mt-0">
                                    <text style={{ color: '#006939', textAlign: 'center' }}>
                                        {PostMsg}
                                    </text>
                                </div>

                                <div className="col-12">
                                    <button type="button" className="btn float-end lightgreenbg text-white px-4"
                                        disabled={IsDisable || isRegistrationLoading || loading}
                                        onClick={handleSubmit}
                                    >
                                        {(isRegistrationLoading || loading) && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {(!isRegistrationLoading || !loading) && <span>Submit</span>}
                                    </button>
                                </div>


                            </p>

                        </form>



                    </div>



                    {/* <!-- <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> --> */}
                </div>
            </div>
        </div>
    )
}