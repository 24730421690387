import { EventGalleryPhotoPostReducer, EventGalleryTableDataReducer } from "./AdminSlice/EventGallarySlice/EventGallarySlice";
import { EventGallaryDeleteReducer, EventMasterDeleteReducer, EventPostReducer, EventTableDataReducer, IndividualPublishPhotoVideoReducer, PublishEventReducer } from "./AdminSlice/EventSlice/EventSlice";
import { NewsLetterDeleteReducer, NewsLetterPostReducer, NewsLetterTableDataReducer } from "./AdminSlice/NewsLetterAdminSlice/NewsLetterAdminSlice";
import { PartenerApprovalPostReducer, PartenerApprovalTableDataReducer } from "./AdminSlice/PartenerApprovalSlice/PartenerApprovalSlice";
import { PartnerCaseStudyDeleteReducer, PartnerCaseStudyPatchReducer, PartnerCaseStudyPostReducer, PartnerCaseStudyTableDataReducer } from "./AdminSlice/PartnerCaseStudySlice/PartnerCaseStudySlice";
import { PartenerReportDeleteReducer, PartenerReportPatchReducer, PartenerReportPostReducer, PartenerReportTableDataReducer } from "./AdminSlice/PartnerReportSlice/PartnerReportSlice";
// import { PartenerReportPatchReducer, PartenerReportPostReducer, PartenerReportTableDataReducer } from "./AdminSlice/PartnerReportSlice/PartnerReportSlice";
import { PledgeParticipantPostReducer, PledgeParticipantTableDataReducer } from "./AdminSlice/PledgeParticipantSlice/PledgeParticipantSlice";
import { ReceivedQueryGetTableDataReducer, ReceivedQueryGetTableDataSliceReducer, ReplyPostReducer } from "./AdminSlice/ReceivedQuerySlice/ReceivedQuerySlice";
import { CountryCodeDDLReducer, CountryTypeDDLReducer, EventNameDDLReducer, EventSubTypeDDLReducer, EventTypeDDLReducer, OrganizationTypeDDLReducer, PartnerNameDDLReducer, PartnerTypeDDLReducer, StatusDDLReducer, UpdatedCountryDDLReducer, UpdatedPartnerNameDDLReducer } from "./DDLSlice";
import { PostPartnerRegistrationReducer, PostQueryReducer } from "./PartnerRegistrationSlice/PartnerRegistrationSlice";
import { PostPledgeRegistrationReducer } from "./PledgeRegistrationSlice/PledgeRegistrationSlice";
import { PostSendEmailReducer } from "./SendEmailSlice/SendEmailSlice";
import { ForgotPasswordReducer } from "./ForgotPasswordSlice/ForgotPasswordSlice";
import { VerifyOTPDataReducer } from "./OTPSlice/OTPSlice";
import { PostChangePasswordReducer } from "./NewPassword/NewPasswordSlice";
import { NewsLetterDataReducer, PhotovideoEventTableDataReducer } from "./MultimediaSlice/MultimediaSlice";
import { CaseStudyDataReducer } from "./CaseStudySlice/CaseStudySlice";

export const reducer = {
    PartnerTypeDDLData: PartnerTypeDDLReducer,
    OrganizationTypeDDLData: OrganizationTypeDDLReducer,
    StatusDDLData: StatusDDLReducer,
    CountryTypeDDLData: CountryTypeDDLReducer,
    CountryCodeDDLData: CountryCodeDDLReducer,

    UpdatedCountryDDLData:UpdatedCountryDDLReducer,

    PostQueryData: PostQueryReducer,
    PostQueryData: PostQueryReducer,
    PostSendEmailData: PostSendEmailReducer,

    PostPartnerRegistrationData: PostPartnerRegistrationReducer,
    PartenerApprovalTableData: PartenerApprovalTableDataReducer,
    PartenerApprovalPostData: PartenerApprovalPostReducer,
    PostPledgeRegistrationData: PostPledgeRegistrationReducer,
    PledgeParticipantTableData: PledgeParticipantTableDataReducer,
    PledgeParticipantPostData: PledgeParticipantPostReducer,
    PartnerCaseStudyTableData: PartnerCaseStudyTableDataReducer,
    PartnerCaseStudyPostData: PartnerCaseStudyPostReducer,
    PartnerCaseStudyPatchData: PartnerCaseStudyPatchReducer,
    PartnerCaseStudyDeleteData: PartnerCaseStudyDeleteReducer,
    PartenerReportTableData: PartenerReportTableDataReducer,
    PartenerReportPatchData: PartenerReportPatchReducer,
    PartenerReportDeleteData: PartenerReportDeleteReducer,
    ReceivedQueryGetTableData: ReceivedQueryGetTableDataReducer,
    EventTypeDDLData: EventTypeDDLReducer,
    EventSubTypeDDLData: EventSubTypeDDLReducer,
    EventPostData: EventPostReducer,
    EventTableData: EventTableDataReducer,
    PartnerNameDDLData: PartnerNameDDLReducer,
    EventMasterDeleteData : EventMasterDeleteReducer,
    EventGallaryDeleteData : EventGallaryDeleteReducer,
    PublishEventData : PublishEventReducer,
    ReplyPostData : ReplyPostReducer,

    UpdatedPartnerNameDDLData:UpdatedPartnerNameDDLReducer,

    NewsLetterTableData: NewsLetterTableDataReducer,
    NewsLetterPostData: NewsLetterPostReducer,
    NewsLetterDeleteData: NewsLetterDeleteReducer,
    EventNameDDLData: EventNameDDLReducer,
    EventGalleryTableData: EventGalleryTableDataReducer,
    EventGalleryPhotoPostData: EventGalleryPhotoPostReducer,

    ForgotPasswordData:ForgotPasswordReducer,
    VerifyOTPData:VerifyOTPDataReducer,
    PostChangePasswordData:PostChangePasswordReducer,
    PartenerReportPostData : PartenerReportPostReducer,

    PhotovideoEventTableData : PhotovideoEventTableDataReducer,
    NewsLetterData : NewsLetterDataReducer,
    CaseStudyData:CaseStudyDataReducer,
    IndividualPublishPhotoVideoData : IndividualPublishPhotoVideoReducer,

}