import { useDispatch } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useState } from "react"
import { PartnerCaseStudyPostAPI } from "../../../../../Redux/AdminSlice/PartnerCaseStudySlice/PartnerCaseStudySlice"
import { AstricSign } from "../../../../../Helper/AstricSign/AstricSign"

export const AddKnowledgeRepositoryCaseStudy = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [PDF_Doc, setPDF_Doc] = useState('')
    const [Subject, setSubject] = useState('')
    const [CaseDate, setCaseDate] = useState('')
    const [Document, setDocument] = useState('')
    const [isPostLoading, setisPostLoading] = useState(false)

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };


    const handlePost = () => {
        const data = {
            M_PartnerRegistrationID: partnerNameDDL.ID,
            CaseDate: CaseDate,
            CaseStudiesSubject: Subject,
            PDF_Doc: Document,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisPostLoading: setisPostLoading
        }
        dispatch(PartnerCaseStudyPostAPI({ data }))
    }

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Partner Report
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                            onClick={handleOnAddClick}> &times; </button>
                    </div>

                    <div className="modal-body px-5" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <PartnerNameDDL
                                        partnerNameDDL={partnerNameDDL}
                                        setpartnerNameDDL={setpartnerNameDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class=''>Upload Date<AstricSign/>
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={CaseDate}
                                        onChange={(e) => setCaseDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row mt-4'>

                                <div className="col-12 col-md-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Subject<AstricSign/>
                                    </label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={Subject}
                                        onChange={(e) => setSubject(e.target.value)} />
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Upload PDF<AstricSign/>
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={handleFileInputChange} />
                                </div>
                            </div>

                            <p className="float-end pt-5 pb-2">
                                <button type="button" className="btn  lightgreenbg text-white px-4"
                                disabled={isPostLoading || partnerNameDDL.ID =='0' || CaseDate =='' || Subject =='' || Document ==''}
                                    onClick={handlePost}>
                                    {isPostLoading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {!isPostLoading && <span>Upload</span>}
                                </button>
                            </p>
                        </form>
                    </div>
                    {/* <!-- <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> --> */}
                </div>
            </div>
        </div>
    )
}