import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import PartnerCaseStudy from '../PartnerCaseStudy/PartnerCaseStudy'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData'

const Reports = () => {

    return (
        <>
            <Header active='Knowledge'/>

            <PagesHeroSection
                Heading='Reports'
                BreadScrum1='Home'
                BreadScrum2='Knowledge Repository'
            />

            <div className='row gray_bg'>
                <div className='col-12 col-md-4 mx-auto text-center gray_bg'>
                <NoData/>
                </div>
            </div>
            {/* <div className="row bg_img" id="herosection">
                <div className="col-12 col-lg-6 d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <h2 class="dark-blue mb-3 animated slideInDown lightgreencolor">Reports</h2>
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active "><a href="#" className="text-dark">Home</a></li><span
                                    className="mx-2 text-dark">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="text-dark">Knowledge Repository</a></li><span className="mx-2 text-dark">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="lightgreencolor fw-bold">Reports</a></li>
                            </ol>
                        </nav>
                    </div>

                </div>
                <div className='col-12 col-lg-6 d-flex align-items-center justify-content-end pe-lg-5 '>
                    <img className='heroIMGLogo' src='assets/img/G_PlaNET_logo.png ' />
                </div>
            </div> */}
            {/* <div className="row p-5"> */}
            {/* <div class="col-12">                


            
            <div className="row p-5">
                {/* <div class="col-12">                

                    <h2 class="lightgreencolor">Reports
                        <span class="float-end">
                            <div class="search-box d-flex justify-content-center align-items-center">
                                <input class="search-input" type="text" name="" placeholder="Type Here" />
                                <a href="#" class="search-btn">
                                    <i class="fas fa-search fs-5"></i>
                                </a>
                            </div>
                        </span>
                    </h2>
                </div> */}


            {/* <hr className=" mt-4 mb-4" /> */}

            {/* <hr className=" mt-4 mb-4" /> */}


            {/* <PartnerCaseStudy /> */}

            {/* </div> */}
            <Footer />
        </>
    )
}

export default Reports