import React, { useEffect } from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData';
import { NewsLetterDataAPI } from '../../../../../Redux/MultimediaSlice/MultimediaSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthState } from '../../../../../Helper/Context';
import moment from 'moment';

const News = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    useEffect(() => {
        const data = {
            MediaDate: '',
            Flag: 'WEB',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
        }

        dispatch(NewsLetterDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.NewsLetterData)


    return (
        <>
            <Header active='Multimedia'/>

            <PagesHeroSection
                Heading='News/NewsLetter'
                BreadScrum1='Home'
                BreadScrum2='Multimedia'
            />

            <div className="row px-3 px-lg-5 py-4">

                {/* {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (
                            <div class="col-12 col-md-6 px-4 mt-1 px-1 px-lg-4">
                                <div class="pt-4">
                                    <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '25px' }} className="py-2" />
                                    <span class="text-dark ps-2"> {i?.subject}</span>

                                    <p class="py-3 font_size fw-lighter border-bottom pb-3">
                                        <LazyLoadImage effect='blur' src="assets/img/calendar.png" alt="About" style={{ width: '15px' }} className="me-2" />
                                        <span>{i.mediaDate ? moment(i.mediaDate).format('MMMM DD YYYY') : ''}</span>
                                        <a href={i?.newsDoc} target='_blank' class="float-end text-success fs-6">Read More... <i
                                            class="fa-solid fa-right-to-bracket ps-2"></i></a>
                                    </p>

                                </div>
                            </div>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                } */}

                <div class="row">
                    <div className='col-12 col-md-4 mx-auto text-center '>
                        <NoData />
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default News