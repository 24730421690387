import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { RegistrationPopUp } from '../../Pages/PopUp/RegistrationPopUp'
import { PledgeRegistration } from '../../Pages/PopUp/PledgeRegistration'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function Header(props) {
  const { active } = props
  const dispatch = useDispatch()
  const userDetails = useAuthState()
  const { username, UserID, token } = userDetails
  const navigate = useNavigate()

  const [showRegistration, setshowRegistration] = useState(false)
  const [showPledge, setshowPledge] = useState(false)

  const handleShowRegistration = () => {
    setshowRegistration(!showRegistration)
  }

  const handleShowPledge = () => {
    setshowPledge(!showPledge)
  }


  return (
    // <div className="container-fluid g-0">
    <div className="row lightgreenbg shadow g-0 nav_position  " id="top_logos"
      onLoad={() => window.scrollTo(0, 0)}>
      <div className="row lightgreenbg shadow g-0 nav_position" id="top_logos">

        <div className="col-12 d-flex  justify-content-between align-items-center mx-auto bg-white top_nav px-2 py-1">

          <div>
            <a onClick={() => navigate('/')} className="navbar-brand g_logo pe-3 ms-2 pc_none ">
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475" alt="" className="top_logos" style={{ width: "70px" }} />
            </a>

            <a onClick={() => navigate('/')} className="navbar-brand g_logo px-3 mobile_none ">
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475" alt="" className="top_logos" style={{ width: "100px" }} />
            </a>

            {/* <a href="index.html" className="navbar-brand g_logo px-2">
              <img src="assets/img/top_logo/G_PlaNET_logo1.png" alt="" className="top_logos" style={{ width: "65px" }} />

            </a> */}

            <span>
              <a onClick={() => navigate('/')} className="text-decoration-none lightgreencolor fw-lighter fs-6 pc_none header_font">
                Global Green Credit Initiative
              </a>
              <a onClick={() => navigate('/')} className="text-decoration-none lightgreencolor fw-lighter fs-4 mobile_none header_font">
                Global Green Credit Initiative
              </a>
            </span>

          </div>

          <div>

            {/* <span onClick={() => navigate('/login')} className="nav-link ms-3 cursor-pointer">Login
              </span> */}

            <span onClick={() => navigate('/login')} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }} >Login</span>

            <span onClick={handleShowRegistration} className="text-white mx-2 mobile_none cursor-pointer me-3 lightgreenbg px-3 py-1" style={{ borderRadius: '5px' }}>Country Registration</span>



            <a onClick={() => navigate('/')} className="navbar-brand ps-3 g_logo1 pc_none">
              {/* assets/img/right_logo.png */}
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos " style={{ width: "30px" }} />
            </a>

            <a onClick={() => navigate('/')} className="navbar-brand ps-3 g_logo1 mobile_none">
              {/* assets/img/right_logo.png */}
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/LoginLogo.png?updatedAt=1701510159304" alt="" className="top_logos " style={{ width: "40px" }} />
            </a>
          </div>
        </div>

      </div>
      {/* <!--end of col top logos--> */}

      <div className="container " id="navbar">
        <nav className="navbar navbar-expand-lg py-0">
          {/* <span onClick={() => navigate('/')} className="navbar-brand d-none">
            <img src="img/right_logo.png" alt="" style={{ width: "80px" }} />
          </span> */}
          {/* <span onClick={() => navigate('/')} className="navbar-brand d-none">
            <img src="img/G_PlaNET_logo.png" alt="" style={{ width: "70px" }} />
          </span> */}
          <i className="navbar-toggler-icon fa-solid fa-bars text-white fs-2 mobile_bar ms-4 mt-3"
            data-bs-toggle="collapse" data-bs-target="#navbarCollapse"></i>

          {/* <span className="px-2 search_span1 pc_none mx-1">
            <input className=" px-2  search_input1" type="search" id="site-search" placeholder="Search"
              name="q" />
            <span className="icon_top search_icon"><i
              className="fa-solid fa-magnifying-glass text-dark fs-7"></i></span>
          </span> */}
          <span className='d-flex'>
            <span onClick={() => navigate('/login')} className="nav-link ms-2 text-white cursor-pointer pc_none">Login
            </span>

            <span onClick={handleShowRegistration} className="nav-link ms-2 text-white cursor-pointer pc_none">Country Registration
            </span>
          </span>

          {/* <!-- <span href="#" className="text-white mx-3 pe-2 pc_none ">Log in</span> --> */}

          <div className="collapse navbar-collapse bg-transparent py-2" id="navbarCollapse">
            <div className="navbar-nav ms-auto mx-xl-auto p-0">
              <span onClick={() => { navigate('/'); window.scrollTo(0, 0) }}
                className={active == 'Home' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>Home</span>

              {/* <div className="nav-item dropdown">
                <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">Join the G-PlaNET Pledge
                  <i className="fas fa-chevron-down arrow_down"></i>
                </span>
                <div className="dropdown-menu rounded">
                  <span onClick={handleShowPledge} className="dropdown-item py-2 border-bottom cursor-pointer" >Registration</span>
                  <span onClick={() => navigate('/Participants')}
                    className="dropdown-item py-2 cursor-pointer">Participants</span>
                  <span onClick={() => navigate('/WorldMap')} className="dropdown-item py-2 cursor-pointer">World Map</span>
                </div>
              </div> */}

              {/* <div className="nav-item dropdown">
                <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">About
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu">
                  <span onClick={() => navigate('/GPlaNET')} className="dropdown-item py-2 border-bottom cursor-pointer">G-PlaNET</span>
                  <span onClick={() => navigate('/gcp')} className="dropdown-item py-2 border-bottom cursor-pointer">GCP</span>
                  <span onClick={() => navigate('/life')} className="dropdown-item py-2 cursor-pointer">LiFE</span>
                </div>
              </div> */}
              <div className="nav-item dropdown">
                <span href="#" className={active == 'Multimedia' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}
                  data-bs-toggle="dropdown">Multimedia
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate('/photos?name=Photo')} className="dropdown-item py-2 border-bottom cursor-pointer">Photos</span>
                  <span onClick={() => navigate('/videos?name=Video')} className="dropdown-item py-2  cursor-pointer">Videos</span>
                  {/* <span onClick={() => navigate('/news')} className="dropdown-item py-2 cursor-pointer">News/Newsletter</span> */}
                  {/* <span onClick={() => navigate('/newsletter')} className="dropdown-item py-2 cursor-pointer">Newsletter</span> */}

                </div>
              </div>

              <div className="nav-item dropdown">
                <span href="#" className={active == 'Knowledge' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Knowledge
                  Repository
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate('/reports')}
                    className="dropdown-item border-bottom py-2 border-bottom cursor-pointer">Reports</span>
                  <span onClick={() => navigate('/casestudy')} className="dropdown-item  py-2 cursor-pointer">Case
                    Study</span>
                  {/* <!-- <span href="testimonial.html" className="dropdown-item py-2">Upload</span> --> */}
                </div>
              </div>





              <div className="nav-item dropdown">
                <span href="#" className={active == 'Resource' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`} data-bs-toggle="dropdown">Resource
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  <span onClick={() => navigate('/faq')} className="dropdown-item py-2 cursor-pointer">FAQ</span>
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2  cursor-pointer">Helpdesk</span> */}

                  {/* <span href="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom" className="dropdown-item py-2 cursor-pointer">Queries</span> */}
                </div>
              </div>

              <div className="nav-item dropdown">
                <span href="#" className="nav-link dropdown-toggle ms-2 cursor-pointer" data-bs-toggle="dropdown">Engage
                  <i className="fas fa-chevron-down arrow_down"></i> </span>
                <div className="dropdown-menu rounded">
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Discussion
                    forum</span> */}
                  {/* <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 border-bottom cursor-pointer">Events</span> */}
                  <span onClick={() => navigate('/Commingsoon')} className="dropdown-item py-2 cursor-pointer">Webinar</span>
                </div>
              </div>
              <span onClick={() => navigate('/contactus')} className={active == 'Contact' ? `nav-item nav-link active text-secondary ms- cursor-pointer me-4` : `nav-item nav-link text-white ms- cursor-pointer me-4`}>Contact Us
              </span>



            </div>
          </div>

        </nav>
      </div >

      {
        showRegistration &&
        <RegistrationPopUp
          handleShowRegistration={handleShowRegistration}
        />
      }

      {
        showPledge &&
        <PledgeRegistration
          handleShowPledge={handleShowPledge}
        />
      }

    </div >
    // </div>
  )
}
