import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr } from "../Helper/ToastMessage";
import { BaseUrl } from "../Helper/BaseURL";


export const PartnerTypeDDLAPI = createAsyncThunk("PartnerTypeDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_PartnerType_Select?M_UserID=${UserID ? UserID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const PartnerTypeDDLSlice = createSlice({
    name: "PartnerTypeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartnerTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(PartnerTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const PartnerTypeDDLReducer = PartnerTypeDDLSlice.reducer

export const OrganizationTypeDDLAPI = createAsyncThunk("OrganizationTypeDDL", async ({ data }) => {
    const {
        M_PartnerTypeID,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_SubPartnerType_Select?M_PartnerTypeID=${M_PartnerTypeID ? M_PartnerTypeID : '0'}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const OrganizationTypeDDLSlice = createSlice({
    name: "OrganizationTypeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(OrganizationTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(OrganizationTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(OrganizationTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const OrganizationTypeDDLReducer = OrganizationTypeDDLSlice.reducer

export const StatusDDLAPI = createAsyncThunk("StatusDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_StatusM_Indicator_Select?Flag=${Flag}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const StatusDDLSlice = createSlice({
    name: "StatusDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(StatusDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(StatusDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(StatusDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const StatusDDLReducer = StatusDDLSlice.reducer



export const CountryTypeDDLAPI = createAsyncThunk("CountryTypeDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_CountryName_Select?M_UserID=${UserID ? UserID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const CountryTypeDDLSlice = createSlice({
    name: "CountryTypeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CountryTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CountryTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(CountryTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const CountryTypeDDLReducer = CountryTypeDDLSlice.reducer


export const UpdatedCountryDDLAPI = createAsyncThunk("UpdatedCountryDDL", async ({ data }) => {
    const {
        M_UserID,
        Flag,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_ScreenWise_ContryName_Select?Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
    .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const UpdatedCountryDDLSlice = createSlice({
    name: "UpdatedCountryDDL",
    initialState: {
        isLoading: false,
        CountryDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UpdatedCountryDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UpdatedCountryDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CountryDDLData = action.payload;
        });
        builder.addCase(UpdatedCountryDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CountryDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const UpdatedCountryDDLReducer = UpdatedCountryDDLSlice.reducer



export const CountryCodeDDLAPI = createAsyncThunk("CountryCodeDDL", async ({ data }) => {
    const {
        UserID, CountryTypeDDL, setCountryCodeID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Country_Wise_MobileCode_Select?M_CountryID=${CountryTypeDDL ? CountryTypeDDL.ID : '0'}&M_UserID=${UserID ? UserID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setCountryCodeID(result.data.table && result.data.table[0].m_CountryID)
                return result.data.table
            } else {
                return result
            }
        })
})

const CountryCodeDDLSlice = createSlice({
    name: "CountryCodeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CountryCodeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CountryCodeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(CountryCodeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const CountryCodeDDLReducer = CountryCodeDDLSlice.reducer

export const EventTypeDDLAPI = createAsyncThunk("EventTypeDDL", async ({ data }) => {
    const { UserID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_EventType_Select?M_UserID=${UserID ? UserID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const EventTypeDDLDDLSlice = createSlice({
    name: "EventTypeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(EventTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const EventTypeDDLReducer = EventTypeDDLDDLSlice.reducer

export const EventSubTypeDDLAPI = createAsyncThunk("EventSubTypeDDL", async ({ data }) => {
    const { UserID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_EventSubType_Select?M_UserID=${UserID ? UserID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const EventSubTypeDDLSlice = createSlice({
    name: "EventSubTypeDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventSubTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventSubTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(EventSubTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const EventSubTypeDDLReducer = EventSubTypeDDLSlice.reducer


export const PartnerNameDDLAPI = createAsyncThunk("PartnerNameDDL", async ({ data }) => {
    const { M_PartnerTypeID, UserID, token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_PartnerName_Select?M_PartnerTypeID=${M_PartnerTypeID ? M_PartnerTypeID : '0'}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const PartnerNameDDLSlice = createSlice({
    name: "PartnerNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartnerNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartnerNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(PartnerNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const PartnerNameDDLReducer = PartnerNameDDLSlice.reducer


export const UpdatedPartnerNameDDLAPI = createAsyncThunk("UpdatedPartnerNameDDL", async ({ data }) => {
    const { Flag, M_UserID, token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_ScreenWise_PartnerName_Select?Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const UpdatedPartnerNameDDLSlice = createSlice({
    name: "UpdatedPartnerNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UpdatedPartnerNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UpdatedPartnerNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(UpdatedPartnerNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const UpdatedPartnerNameDDLReducer = UpdatedPartnerNameDDLSlice.reducer



export const EventNameDDLAPI = createAsyncThunk("EventNameDDL", async ({ data }) => {
    const { M_EventTypeID, UserID, token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Event_Select?M_EventTypeID=${M_EventTypeID ? M_EventTypeID : '0'}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result
            }
        })
})

const EventNameDDLSlice = createSlice({
    name: "EventNameDDL",
    initialState: {
        isLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(EventNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const EventNameDDLReducer = EventNameDDLSlice.reducer