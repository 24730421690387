
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";

// Get Table Data
export const EventGalleryTableDataAPI = createAsyncThunk("EventGalleryTableData", async ({ data }) => {
    const {
        M_EventID,
        M_PartnerRegistrationID,
        Flag,
        M_UserID,
        token,
        Top,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Event_Wise_Photo_Video_Select?M_EventID=${M_EventID ? M_EventID : '0'}&M_PartnerRegistrationID=${M_PartnerRegistrationID ? M_PartnerRegistrationID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EventGalleryTableDataSlice = createSlice({
    name: "EventGalleryTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventGalleryTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventGalleryTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EventGalleryTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EventGalleryTableDataReducer = EventGalleryTableDataSlice.reducer

// InsertUpdate Table Data

export const EventGalleryPhotoPostAPI = createAsyncThunk("EventGalleryPhotoPost", async ({ data, PhotoPath, VideoPath,setloading }) => {
    const {
        M_EventID,
        M_PartnerRegistrationID,
        M_UserID,
        token,
        handleIsPost,
        handleClear,
        flag
        
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_PartnerRegistrationID", M_PartnerRegistrationID);
    formdata.append("M_EventID", M_EventID);
    formdata.append("M_UserID", M_UserID);
    if (flag === 'Photo') {
        formdata.append("PhotoPath", PhotoPath);
    } else {
        formdata.append("VideoPath", VideoPath);
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    let url;
    if (flag === 'Photo') {
        url = `${BaseUrl}/Master/Post_M_Event_Wise_Photo_Insert`
    } else {
        url = `${BaseUrl}/Master/Post_M_Event_Wise_Video_Insert`
    }

    setloading(true)
    fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleClear(flag)
                // handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(false)
            return result
        })
})

const EventGalleryPhotoPostSlice = createSlice({
    name: "EventGalleryPhotoPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EventGalleryPhotoPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EventGalleryPhotoPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(EventGalleryPhotoPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EventGalleryPhotoPostReducer = EventGalleryPhotoPostSlice.reducer



